'use strict';

import Component from '_core_ext/components/Component';
import util from '_core_ext/util';
import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('fdstepsca');
const moment = require('moment');

const CLASS_PATTERN = '.js-fd-step';
const DEFAULT_CITY = 'Montreal';
const DEFAULT_COUNTRY = 'CA';
const STORAGE_TTL = 8.64e7;
const TIME = {
    Montreal: 12,
    months: 3,
    threshold: 600
};

export default class FlightDetailsSteps extends Component {
    init() {
        super.init();
        this.error = '';
        this.step = window.localStorage.getItem('fdStep') ? 1 * window.localStorage.getItem('fdStep') : 1;
        window.localStorage.removeItem('fdStep');
        // jscs: disable
        this.sessionCountry = this.$el.attr('data-session') !== 'null' ? this.$el.attr('data-session') : null;
        // jscs: enable
        this.locale = this.$el.data('locale');
        this.checkURL = $('.js-fd-step-7').attr('data-url');
        this.state = this.getState();
        if (this.state.date) {
            this.prepareCalendar();
        }
        this.isFlying = true;

        $('.js-fd-button-flightnumber-next').addClass('disabled');

        this.setTimeMargins();
        this.fillResult();
        this.showCurrentStep();
        this.fireEvents();
        this.prepareSelectors();
    }

    getState () {
        let state = {
            citytype: 'departure',
            city: 'Montreal',
            terminal: '',
            connections: [],
            connectionsCount: 1,
            date: null,
            time: null,
            country: {
                from: {
                    value: 'CA', label: 'Canada'
                },
                to: {
                    value: null, label: null
                }
            },
            isTransfer: false,
            flightNumber: '',
            locale: this.locale,
            timestamp: new Date().getTime()
        },
        storedState = {};

        if (window.localStorage.getItem('fdState')) {
            try {
                storedState = JSON.parse(window.localStorage.getItem('fdState'));
                // data not restores if user changed locale or localStorage saved more than 24 hours
                if (storedState.locale === this.locale && storedState.timestamp && state.timestamp - storedState.timestamp < STORAGE_TTL) {
                    state.date = storedState.date || '';
                    state.time = storedState.time || '';
                    state.isTransfer = storedState.isTransfer || false;
                    state.connections = storedState.connections || [];
                    state.connectionsCount = storedState.connectionsCount || 1;
                    state.country.to = storedState.country.to;
                    state.flightNumber = storedState.flightNumber || '';
                } else {
                    localStorage.removeItem('fdState');
                }
            } catch (e) {
                // console.warn(`Couldn't parse FD storage: ${e}`);
            }
        }

        return state;
    }

    fireEvents () {
        this.event('click', '.js-fd-go-back', this.goBack);

        // First step started
        this.event('click', '.js-fd-button-proceed', () => {
            this.showStepNumber(3);
        });

        // Screen Why
        this.event('click', '.js-fd-button-why', () => {
            this.showStepNumber(2);
        });

        // Date - time selection
        this.event('click', '.js-fd-button-calendar-date', () => {
            this.state.date = $('.js-fd-button-calendar-date').text();
            if (this.validateMonth()) {
                $('.js-select-time-main').removeClass('h-hidden');
                this.showStepNumber(4);
                this.storeState();
            } else {
                $('.js-fd-progress-line').width(`100%`);
                this.showAndHide(['.js-fd-step-3-error', '.js-fd-step-3-main']);
            }
        });

        this.event('click', '.js-fd-button-calendar-time', this.onTimeSelected);

        // Direction selection
        this.event('click', '.js-fd-button-direct', () => {
            this.showAndHide(['.js-flightdetails-direct', '.js-flightdetails-connection', '.js-flightdetails-connection-countries']);
            this.state.isTransfer = false;
            emitter.emit('closeselector');
            this.prefillSelectors();
            this.checkThreshold();
            this.showStepNumber(6);
        });
        this.event('click', '.js-fd-button-connection', () => {
            this.showAndHide(['.js-flightdetails-connection', '.js-flightdetails-direct', '.js-flightdetails-connection-countries']);
            this.state.isTransfer = true;
            if (this.state.connectionsCount > 1) {
                $('.js-fd-connection-quantity').val(`${this.state.connectionsCount}`);
            }
            emitter.emit('closeselector');
            this.prefillSelectors();
            this.showStepNumber(6);
        });

        this.event('click', '.js-fd-button-from-to', () => {
            if ($('.js-fd-country-to').attr('data-value')) {
                this.state.country.to = {
                    label: $('.js-fd-country-to').attr('data-label'),
                    value: $('.js-fd-country-to').attr('data-value')
                };
                emitter.emit('closeselector');
                this.saveCountry();
                this.fillResult();
                this.fillForm();
                this.showStepNumber(7);
            }
        });
        this.event('click', '.js-fd-button-connection-points', () => {
            this.state.country.to = {
                label: $('.js-fd-country-connection-to').attr('data-label'),
                value: $('.js-fd-country-connection-to').attr('data-value')
            };
            emitter.emit('closeselector');
            this.saveCountry();
            this.prepareConnections();
            this.showAndHide(['.js-flightdetails-connection-countries', '.js-flightdetails-connection']);
            this.checkThreshold();
        });
        this.event('change', '.js-fd-connection-quantity', () => {
            this.state.connectionsCount = $('.js-fd-connection-quantity').val();
            emitter.emit('closeselector');
            this.storeState();
        });

        this.event('click', '.js-fd-button-connection-proceed', () => {
            this.state.country.to = {
                label: $('.js-fd-country-connection-to').attr('data-label'),
                value: $('.js-fd-country-connection-to').attr('data-value')
            };
            emitter.emit('closeselector');
            this.saveCountry();
            this.prepareConnections(true);
            this.fillResult();
            this.fillForm();
            this.showStepNumber(7);
        });

        // local links from Flight Connections detail to previous state
        this.event('click', '.js-fd-connection-step-back', () => {
            emitter.emit('closeselector');
            this.showAndHide(['.js-flightdetails-connection', '.js-flightdetails-connection-countries', '.js-flightdetails-direct']);
        });
        this.event('click', '.js-fd-connection-step-back-type', () => {
            emitter.emit('closeselector');
            this.showAndHide(['.js-flightdetails-direct', '.js-flightdetails-connection', '.js-flightdetails-connection-countries']);
            this.showStepNumber(5);
        });

        // Flight Number checking block
        this.event('click', '.js-fd-button-flightnumber-next', () => {
            this.checkFlightNumber();
        });
        $('.js-fd-input-flight-number').on('focus', () => {
            $('.js-fd-flightnumber-error').addClass('h-hidden').text('');
        });

        // ignoring "ENTER" key for flight Number input field
        this.event('keydown', '.js-fd-input-flight-number', (el, e) => {
            $('.js-fd-button-flightnumber-next').removeClass('disabled');
            if (e.keyCode === 13) {
                e.preventDefault();
            }
        });

        // redirect to Checkout steps from Result step
        this.event('click', '.js-fd-step-8', (el, e) => {
            let $elem = $(e.target),
                step = 1 * $elem.attr('data-step');

            if ($elem.hasClass('link__to-local-step') && step) {
                switch (step) {
                    case 6:
                        if (this.state.isTransfer) {
                            this.showAndHide(['.js-flightdetails-connection', '.js-flightdetails-connection-countries', '.js-flightdetails-direct']);
                        } else {
                            this.showAndHide(['.js-flightdetails-direct', '.js-flightdetails-connection', '.js-flightdetails-connection-countries']);
                        }
                        emitter.emit('closeselector');
                        break;
                }
                this.step = step;
                this.showCurrentStep();
            }
        });

        // store connection country when country selector changed
        this.eventMgr('countryselector.change', () => {
            this.checkProcessButtons();
            this.checkThreshold();
            this.storeState();
        });
    }

    goBack () {
        if (this.step === 1) {
            let basketUrl = $('.b-checkout-flightdetails__back-to-basket').find('a').attr('href');

            window.location.href = basketUrl;
            return;
        }

        switch (this.step) {

            case 3:
                if ($('.js-fd-step-3-error').hasClass('h-hidden')) {
                    this.step = 1;
                } else {
                    this.showAndHide(['.js-fd-step-3-main', '.js-fd-step-3-error']);
                }
                break;

            case 4:
                if (this.error) {
                    this.showAndHide(['.js-select-time-main', `.${this.error}`]);
                    this.error = false;
                } else {
                    this.step--;
                }
                break;

            case 5:
                if ($('.js-flightdetails-connection-countries').hasClass('h-hidden')) {
                    this.step--;
                } else {
                    this.showAndHide(['.js-flightdetails-connection', '.js-flightdetails-connection-countries']);
                }
                emitter.emit('closeselector');
                break;

            case 6:
                // for regular user show connections if it presents
                if (this.state.isTransfer) {
                    if ($('.js-flightdetails-connection-countries').hasClass('h-hidden')) {
                        this.step--;
                    } else {
                        this.showAndHide(['.js-flightdetails-connection', '.js-flightdetails-connection-countries', '.js-flightdetails-direct']);
                    }
                } else {
                // or show direct flight
                    this.showAndHide(['.js-flightdetails-direct', '.js-flightdetails-connection-countries', '.js-flightdetails-connection']);
                    this.step--;
                }
                emitter.emit('closeselector');
                break;

            default:
                this.step--;
                break;
        }
        this.showStepNumber(this.step, true);
    }

    showCurrentStep() {
        $(CLASS_PATTERN).addClass('h-hidden');
        $(`${CLASS_PATTERN}-${this.step}`).removeClass('h-hidden');
        if (this.step > 1) {
            $('.js-fd-progress-line').width(`${this.step / 8 * 100}%`);
        }
    }

    checkStepAvailability(n) {
        return $(`${CLASS_PATTERN}-${n}`).length;
    }

    showStepNumber (n, isGoBack) {
        while (!this.checkStepAvailability(n)) {
            n += isGoBack ? -1 : 1;
            if (n < 0 || n > 20) {
                break;
            }
        }
        this.step = n;
        switch (this.step) {

            case 4:
                // restore time if it was stored
                if (this.state.time) {
                    let hours = this.state.time.split(':')[0],
                        minutes = this.state.time.split(':')[1];

                    $('.js-fd-timepicker-hours').attr('data-value', hours).find('.js-timepicker-value').text(hours);
                    $('.js-fd-timepicker-minutes').attr('data-value', minutes).find('.js-timepicker-value').text(minutes);
                }
                break;
        }
        this.showCurrentStep();
    }

    onTimeSelected() {
        this.validateDate().then((result) => {
            this.error = result;
            if (this.error) {
                $('.js-fd-progress-line').width(`100%`);
                this.showAndHide([`.${this.error}`, '.js-select-time-main']);
            } else {
                this.state.time = `${$('.js-fd-timepicker-hours').attr('data-value')}:${$('.js-fd-timepicker-minutes').attr('data-value')}`;
                this.storeState();
                this.showStepNumber(5);
            }
        });
    }

    validateDate() {
        return new Promise((resolve) => {
            const flyday = new Date(1 * $('.js-fd-button-calendar-date').attr('data-date'));
            const hours = $('.js-fd-timepicker-hours').attr('data-value');
            const minutes = $('.js-fd-timepicker-minutes').attr('data-value');
            let result = false;

            flyday.setHours(parseInt(hours, 10));
            flyday.setMinutes(parseInt(minutes, 10));

            $.ajax({
                url: util.appendParamsToUrl(Urls.flightDetailsTimeValidation, {
                    t: moment(flyday).format('dddd, DD.MM.YYYY HH:mm'),
                    c: this.state.city
                })
            }).done(data => {
                if (!data || !data.success) {
                    result = 'js-select-time-error-leaving';
                    return resolve(result);
                }

                this.state.date = $('.js-fd-button-calendar-date').text();
                this.state.time = `${hours}:${minutes}`;
                resolve(result);
            });
        });
    }

    validateMonth () {
        let today = new Date(),
            flyday = new Date(1 * $('.js-fd-button-calendar-date').attr('data-date'));

        today.setMonth(today.getMonth() + TIME.months);
        return flyday.getTime() < today.getTime();
    }
/*
* mode: a flag for processing connection points:
* true  - collect connection points values
* false - render connection poins selectors
*/
    prepareConnections (mode = false) {
        let number = 1 * $('.js-fd-connection-quantity').val(),
            isProcess = true;

        if (mode) {
            this.state.connections = [];
            for (let i = 1; i < 4; i++) {
                if (i <= number) {
                    this.state.connections.push($(`.js-fd-connection-item-value-${i}`).attr('data-label'));
                }
            }
        } else {
            $('.js-fd-connection-number-value').text(number);
            $('.js-fd-connection-label-to').text($('.js-fd-country-connection-to').attr('data-label'));

            for (let i = 1; i < 4; i++) {
                if (i <= number) {
                    $(`.js-fd-connection-item-${i}`).removeClass('h-hidden');
                    if (this.state.connections[i - 1]) {
                        let selectedValue = this.getCountryValue($(`.js-fd-connection-item-value-${i}`), this.state.connections[i - 1]);

                        $(`.js-fd-connection-item-value-${i} .js-country-selector-value`).text(this.state.connections[i - 1]);
                        $(`.js-fd-connection-item-value-${i}`).attr('data-label', this.state.connections[i - 1]);
                        $(`.js-fd-connection-item-value-${i}`).attr('data-value', selectedValue);
                    } else {
                        isProcess = false;
                    }
                } else {
                    $(`.js-fd-connection-item-${i}`).addClass('h-hidden');
                }
            }
            if (isProcess) {
                $('.js-fd-button-connection-proceed').removeClass('disabled');
            } else {
                $('.js-fd-button-connection-proceed').addClass('disabled');
            }
        }
    }

    storeState () {
        window.localStorage.setItem('fdState', JSON.stringify(this.state));
    }

    checkProcessButtons() {
        // jscs: disable
        if ($('.js-fd-country-to').attr('data-value') !== '') {
            $('.js-fd-button-from-to').removeClass('disabled');
        } else {
            $('.js-fd-button-from-to').addClass('disabled');
        }
        if ($('.js-fd-country-connection-to').attr('data-value') !== '') {
            $('.js-fd-button-connection-points').removeClass('disabled');
        } else {
            $('.js-fd-button-connection-points').addClass('disabled');
        }
        // jscs: enable
    }

    prefillSelectors () {
        let selected = this.sessionCountry;

        this.renderCountrySelector('.js-fd-country-to', selected === DEFAULT_COUNTRY ? null : selected);
        this.renderCountrySelector('.js-fd-country-connection-to', selected);
        this.checkProcessButtons();
    }

    renderCountrySelector (el, country) {
        let $item;

        $(el).removeClass('disabled');
        if (country) {
            $item = $(`${el} ul li[data-value="${country}"]`);
            $(el).attr('data-value', $item.attr('data-value'))
                .attr('data-label', $item.text());
            if ($item.attr('data-value')) {
                $(`${el} .js-country-selector-value`).text($item.text());
            }
        } else {
            $(`${el} .js-country-selector-value`).text('Select Country');
            $(el).attr('data-value', '').attr('data-label', '');
        }
    }

    /*
    * a helper function for show first parameter and hide other
    * params - an array of string to use it as jQuery selector
    * show/hide makes via adding/removing 'h-hidden' class
    */
    showAndHide (params) {
        $(params[0]).removeClass('h-hidden');
        for (let i = 1; i < params.length; i++) {
                $(params[i]).addClass('h-hidden');
        }
    }

    checkFlightNumber () {
        let number = $('.js-fd-input-flight-number').val().trim();

        $('.js-fd-button-flightnumber-next').addClass('disabled');
        if (number === '') {
             $('.js-fd-flightnumber-error').removeClass('h-hidden').text('This field is required');
             return;
        }

        $.ajax({
             url: this.checkURL,
             data: {
                 number: number
             }
         }).done(data => {
             if (data.success) {
                 $('.js-fd-button-flightnumber-next').removeClass('disabled');
                 $('.js-fd-flightnumber-error').addClass('h-hidden');
                 this.state.flightNumber = number;
                 $('form.b-flightdetails .js-fd-form-flightnumber').val(this.state.flightNumber);
                 this.showStepNumber(8);
             } else {
                $('.js-fd-flightnumber-error').removeClass('h-hidden').text('Please enter a valid flight number');
             }
         })
         .fail(() => {
             $('.js-fd-button-flightnumber-next').removeClass('disabled');
         });
    }

    saveCountry () {
        let country = this.state.country.to.value,
            url = util.appendParamsToUrl(Urls.flightDetailsSaveCountry, {
            country: country,
            format: 'ajax'
        });

        $.ajax({
            url: url
        }).done(() => {
            // do nothing
        });
    }

    setTimeMargins () {
        try {
            this.margins = JSON.parse(this.$el.attr('data-margins-json'));
        } catch (e) {
            this.margins = null;
        }
        if (this.margins) {
            TIME.Montreal = this.margins.minimalMontreal;
            TIME.months = this.margins.monthLimit;
            TIME.threshold = 1.0 * this.margins.threshold;
        }
        this.prices = $('.js-fd-step-1').data('prices') ? $('.js-fd-step-1').data('prices') : [];
        this.thresholdWarn = this.prices.filter(item => item > TIME.threshold);
        $('.js-fd-threshold-error').text($('.js-fd-threshold-error').text().replace('XXXXX', TIME.threshold));
        $('.js-fd-threshold-error-conn').text($('.js-fd-threshold-error-conn').text().replace('XXXXX', TIME.threshold));
    }

    fillResult () {
        $('.js-fd-review-arriving').text('departing');
        $('.js-fd-review-destination').text(this.state.city);
        $('.js-fd-review-date').text(this.state.date);
        $('.js-fd-review-time').text(this.state.time ? this.state.time.replace(/:/, 'h') : '');
        $('.js-fd-review-to').text(this.state.country.to.label);

        if (this.state.isTransfer) {
            if ($('.js-fd-connection-item-value-1').attr('data-value') === 'US') {
                this.showAndHide(['.js-fd-review-us-departures', '.js-fd-review-int-departures']);
            } else {
                this.showAndHide(['.js-fd-review-int-departures', '.js-fd-review-us-departures']);
            }
            $('.js-fd-result-connection-block').removeClass('h-hidden');
            $('.js-fd-review-connection').text(this.state.connections.join(', '));
        } else {
            if ($('.js-fd-country-to').attr('data-value') === 'US') {
                this.showAndHide(['.js-fd-review-us-departures', '.js-fd-review-int-departures']);
            } else {
                this.showAndHide(['.js-fd-review-int-departures', '.js-fd-review-us-departures']);
            }
            $('.js-fd-result-connection-block').addClass('h-hidden');
        }
        this.storeState();
    }

    fillForm () {
        $('form.b-flightdetails .js-fd-form-citytype').val(this.state.citytype);
        $('form.b-flightdetails .js-fd-form-city').val(DEFAULT_CITY);
        $('form.b-flightdetails .js-fd-form-country').val(this.state.country.to.value);
        $('form.b-flightdetails .js-fd-form-isflying').val('yes');
        $('form.b-flightdetails .js-fd-form-date').val(this.state.date + ' ' + this.state.time);
        $('form.b-flightdetails .js-fd-form-istransfer').val(this.state.isTransfer ? 'yes' : 'no');
        $('form.b-flightdetails .js-fd-form-transfercountry').val(this.getTransferCountries());
        $('form.b-flightdetails .js-fd-form-flightnumber').val(this.state.flightNumber);
    }

    prepareDateTime () {
        let date = new Date($('.js-fd-button-calendar-date').data('date'));

        date.setHours(this.state.time.split(':')[0]);
        date.setMinutes(this.state.time.split(':')[1]);
        date.setSeconds(0);
        return date.getTime();
    }

    getTransferCountries () {
        // country list gets from destination country selector component
        let countries,
            result = [],
            locale = $('.js-fd-country-to').data('locale');

        if (!this.state.isTransfer || !this.isFlying || this.state.connections.length === 0) {
            return '';
        }

        countries = $('.js-fd-country-to').data('json-config')[locale];
        this.state.connections.map(name => {
            for (let item in countries) {
                if (name === countries[item].label) {
                    result.push(countries[item].value);
                }
            }
        });
        // only first country will be processed by requirement from client
        return result[0];
    }

    prepareSelectors () {
        // hide Canada into some destination selectors
        let selectors = ['.js-fd-country-to', '.js-fd-connection-item-value-1', '.js-fd-country-connection-to'];

        selectors.map(item => {
            $(item).find(`ul li[data-value="${DEFAULT_COUNTRY}"]`).addClass('h-hidden');
        });
    }

    checkThreshold () {
        // if dest Country is USA for Direct, or
        // first connection country is USA - needs to check Threshold
        if (!$('.js-flightdetails-direct').hasClass('h-hidden')) {
            if ($('.js-fd-country-to').attr('data-value') === 'US' && this.thresholdWarn.length > 0) {
                $('.js-fd-threshold-error').removeClass('h-hidden');
                $('.js-fd-button-from-to').addClass('disabled');
            } else {
                $('.js-fd-threshold-error').addClass('h-hidden');
            }
        }
        if (!$('.js-flightdetails-connection-countries').hasClass('h-hidden')) {
            if ($('.js-fd-connection-item-value-1').attr('data-label') === 'United States' && this.thresholdWarn.length > 0) {
                $('.js-fd-threshold-error-conn').removeClass('h-hidden');
                $('.js-fd-button-connection-proceed').addClass('disabled');
            } else {
                $('.js-fd-threshold-error-conn').addClass('h-hidden');
            }
        }
    }

    getCountryValue ($component, country) {
        let locale = $component.data('locale'),
            countries = $component.data('json-config')[locale],
            result = null;

        for (let key in countries) {
            if (countries[key].label === country) {
                result = key;
                break;
            }
        }
        return result;
    }

    prepareCalendar () {
        let locale = this.locale.replace('_', '-').toLowerCase(),
            date,
            today = new Date().getTime();

        moment.locale(locale);
        try {
            date = moment(this.state.date, 'dddd, DD MMMM YYYY');
        } catch (e) {
            date = moment().add(1, 'second');
        }

        if (isNaN(date) || date.diff(today) < 0) {
            date = moment().add(1, 'second');
        }
        $('.js-datetimepicker').data('DateTimePicker').options({
            defaultDate: date,
            format: 'LL'
        });
    }
}

module.exports = FlightDetailsSteps;
