'use strict';

import CreditCardListBase from '_core_ext/components/account/CreditCardList';
import {redirect as pageRedirect} from '_core_ext/page';
import {appendParamsToUrl} from '_core_ext/util';
import eventMgr from '_core_ext/eventMgr';

export default class CreditCardList extends CreditCardListBase {
    init () {
        super.init();
    }

    removeCC() {
        const
            token = this.$el.find('.js-cc-token').data('cc-token'),
            url = appendParamsToUrl(Urls.removeCreditCard, {
                format: 'ajax'
            });

        $.ajax({
            url: url,
            data: {
                token: token
            }
        }).done(function (data) {
            if (data) {
                let $updates = $(data).find('.js-credit-card-list-wrapper');

                $document.find('.js-credit-card-list-wrapper').replaceWith($updates);
            } else {
                pageRedirect(Urls.paymentsList);
            }
        });

        super.getEmitter().emit('closedialog');
    }
}
