'use strict';

import ProductSetActionsBase from '_core_ext/components/product/ProductSetActions';
import util from '_core_ext/util';

export default class ProductSetActions extends ProductSetActionsBase {
    init() {
        super.init();
    }

    updateSetItem (element, event) {
        event.preventDefault();

        if ($(element).parents('li').hasClass('unselectable')) { return; }

        let url = Urls.getSetItem + element.search;
        let $container = $(element).closest('.js-product-set-item');
        let qty = $container.find('input[name="Quantity"]').first().val();
        let params = {
            Quantity: isNaN(qty) ? '1' : qty,
            format: 'ajax'
        };
        let self = this;

        $.ajax({
            url: util.appendParamsToUrl(url, params),
            type: 'GET'
        }).done(data => {
            $container.html(data);
            self.updateAddAllToCartBtn();
        }).fail(() => {

        });
    }
}
module.exports = ProductSetActions;