/**
 *    (c) 2009-2014 Demandware Inc.
 *    Subject to standard usage terms and conditions
 *    For all details and documentation:
 *    https://bitbucket.com/demandware/sitegenesis
 */
/* sonar-ignore-start */
'use strict';
/* jshint ignore:start */
// if jQuery has not been loaded, load from google cdn
if (!window.jQuery) {
    var s = document.createElement('script');

    s.setAttribute('src', '//ajax.googleapis.com/ajax/libs/jquery/3.1.1/jquery.min.js');
    s.setAttribute('type', 'text/javascript');
    document.getElementsByTagName('head')[0].appendChild(s);

}

require('_core_ext/js-ext')();

var app = window.app = {
    init: function () {
        let references = require('_app_ca/references-config'),
            ComponentMgr = require('_core_ext/components/ComponentMgr');

        window.componentMgr = window.componentMgr || new ComponentMgr(references);
    },
    initGlobalObjects: function () {
        // Global jquery selected objects.

        window.$window = window.$window || $(window);
        window.$document = window.$document || $(document);
        window.$html = window.$html || $('html, body');
        window.$body = window.$body || $('body');
    },
    initGlobalEvents: function (params) {
        $document.on('scroll', function () {
            params.documentEmitter.emit('scroll');
        });
        $document.ready(function () {
            params.documentEmitter.emit('ready');
        });
        $window.resize(function () {
            params.windowEmitter.emit('resize');
        });
    },
    initLoader: function () {
        window.$loader = $('<div class="b-loader"><div class="b-loader-wrapper"><span class="b-loader-copy"></span></div></div>').hide();
        $('body').append(window.$loader);
    }
};

// initialize app
$(document).ready(function () {
    var eventMgr = require('_core_ext/eventMgr'),
        documentEmitter = eventMgr.getEmitter('document'),
        windowEmitter = eventMgr.getEmitter('window');

    app.initGlobalObjects();
    app.init();
    app.initGlobalEvents({
        documentEmitter: documentEmitter,
        windowEmitter: windowEmitter
    });
    app.initLoader();

    documentEmitter.emit('ready');
    $('html').addClass($('.b-country-selector__currently').data('currentlocale'));
});
/* jshint ignore:start */
/* sonar-ignore-end */