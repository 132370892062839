import BillingFormBase from '_core_ext/components/checkout/BillingForm';

export default class BillingForm extends BillingFormBase {
    init() {
        super.init();
    }

    onSubmitForm(el, e) {
        const isFormValid = this.isValid();

        this.eachChild(item => {
            if (typeof item.validateInputField === 'function') {
                item.validateInputField();
            }
        });

        if (!isFormValid) {
            return false;
        }

        if (window.Resources.PXPAY_IN_IFRAME) {
            e.preventDefault();

            let dialogConfig = JSON.parse(this.$cache.authipayDialog.attr('data-json-config'));

            dialogConfig.data = this.$el.serialize();
            super.getEmitter().emit('refreshdata', dialogConfig);
            this.$cache.authipayDialog.trigger('click');

            return false;
        }

        return true;
    }
}
module.exports = BillingForm;