'use strict';

import DialogBase from '_core_ext/components/common/Dialog';
import {redirect as pageRedirect} from '_core_ext/page';
import {appendParamsToUrl} from '_core_ext/util';
import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('dialogca');
export default class Dialog extends DialogBase {
    replace (config) {
        let self = this;
        let url = config.url;
        let emitter = super.getEmitter();

        if (!this.$container) {
            return;
        }

        if (url) {
            config.url = appendParamsToUrl(url, this.config.urlParams);

            $.ajax({
                url: config.url,
                data: config.data,
                type: config.type || 'GET'
            }).done(response => {
                if (!response) {
                    emitter.emit('nocontent');
                }

                if (response.redirectUrl) {
                    return pageRedirect(response.redirectUrl);
                }

                config.content = config.replaceSelector ? $(response).find(config.replaceSelector) : response;
                self.openWithContent(config);
                self.sendGtmData(config);
                return false;
            }).fail(() => {
                // ToDo: handle error
            });
        } else if (config.html) {
            this.openWithContent(config);
            this.sendGtmData(config);
        }
    }

    sendGtmData(config) {
        const recommendationArr = this.$container.find('.js-recommendation-slot-wrapper');
        const recommendationWrapper = recommendationArr.length ? recommendationArr[0] : null;
        if (recommendationWrapper && $(recommendationWrapper).is(':empty')) {
            const observerConfig = { attributes: false, childList: true, subtree: true };
            const observerCallback = function(mutationsList, observer) {
                const impressionProduct = $(mutationsList[0].target).find('.js-product-gtm').first().data('productdetails');
                emitter.emit('gtm-open', config, impressionProduct);
                observer.disconnect();
            };

            var observer = new MutationObserver(observerCallback);
            observer.observe(recommendationWrapper, observerConfig);
        } else if (recommendationWrapper) {
            const impressionProduct = $(recommendationWrapper).find('.js-product-gtm').first().data('productdetails');
            emitter.emit('gtm-open', config, impressionProduct);
        } else {
            emitter.emit('gtm-open', config);
        }
    }
}
module.exports = Dialog;
